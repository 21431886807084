import React, { useCallback } from "react"
import HeaderV2 from "../headerv2/headerv2"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import ClientResults from "../../microcomponents/clientresults/clientresults"
import Image from "../../components/image-component/image-new"
import { pageContent } from "../../utilities/clientlogos"
import Container from "../../microcomponents/container/container"
import HeroNoImage from "../../microcomponents/heronoimage/heronoimage"
import "./clientsv2.scss"
export default function ClientsV2() {
  const filterCustomers = useCallback((item = "all", currentItem) => {
    const allCustomers = document.querySelectorAll(`div[data-filter]`)
    const allButtons = document.querySelectorAll(`.filter-button`)
    allButtons.forEach(element => element.classList.remove("active"))
    if (item.toLowerCase() === "all") {
      allCustomers.forEach(
        element => element.classList.remove("hidelogotag")

        // element.style.display = "flex"
      )
    } else {
      allCustomers.forEach(element => {
        if (element.getAttribute("data-filter").includes(item.toLowerCase())) {
          // element.style.display = "flex"
          element.classList.remove("hidelogotag")
          element.classList.add("showlogotag")
        } else element.classList.add("hidelogotag")
        // element.style.display = "none"
      })
    }
    currentItem.target.classList.add("active")
  })

  const TabsComponent = () => {
    return (
      <div className="full filters-wrapper">
        <div className="filters-wrapper-inner">
          {pageContent?.filters?.map((item, index) => {
            return (
              <button
                key={index}
                className={`filter-button ${index === 0 ? "active" : ""}`}
                onClick={e => filterCustomers(item?.filterTag, e)}
              >
                {item?.filterName}
              </button>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div>
      <HeaderV2 version="v2" />
      <HeroNoImage
        className="honey greeneclipse"
        heading="Clients"
        description="<p>Over 300 clients served, from startups to enterprises.</p>"
        component={<TabsComponent />}
      />
      <Container className="pd-112">
        <div className="customer-logos">
          {pageContent.images.map((item, index) => {
            return (
              <div key={index} data-filter={item.imageTag}>
                <Image
                  sourceFolder={"spiralyze2024website"}
                  imgName={item.imageName}
                  className={"customer-logo"}
                  cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
                  alt={item?.imageAlt ? item?.imageAlt : "Customer logos"}
                />
              </div>
            )
          })}
        </div>
      </Container>

      <TopPredictions version="v2" />
      <ClientResults version="v2" />
    </div>
  )
}
